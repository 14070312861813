import React from 'react';
import './App.css';
import { SoundCloud } from './components';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Guy Hawkins
        </p>
      </header>
      <div className="card">
        <h2 className="card-header">Latest Blog Post</h2>
        <a href="https://aws.amazon.com/blogs/industries/unlock-powerful-genomic-insights-with-aws-healthomics-analytics-and-amazon-emr/">
        Unlock Powerful Genomic Insights with AWS HealthOmics Analytics and Amazon EMR
        </a>
      </div>
      <div className="card">
        <h2 className="card-header">Music</h2>
        <SoundCloud />
      </div>
    </div>
  );
}

export default App;
